import React from "react";
import ContactFormFr from "./ContactFormFr";

export default function FooterFr() {

    return (
        <>
            <ContactFormFr />
        </>
    )
}