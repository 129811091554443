import React from "react";
import ContactForm from "./ContactForm";

export default function Footer() {

    return (
        <>
            <ContactForm />
        </>
    )
}